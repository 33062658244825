import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import Sector from "../templates/sector/sector";
import {cHeight} from "../utils/responsiveness"
import { Title,TitleSpan,HeadingStyle1,HeadingStyle2 } from "../components/widget/text";

const WpPost = ({data}) => {
    const post = data;
    console.log(post)
    return (
        <Layout>
            <Sector data={post} title={TitleDiv()}>

            </Sector>
        </Layout>
    )
  }

  /// Custom Title Div
  function TitleDiv() {
      return(
        <Title style={{marginTop: cHeight(7)}} >
        <TitleSpan> 
            <HeadingStyle1>
            RETAIL
            </HeadingStyle1>
        </TitleSpan>
      </Title>
      )
  }



export const query = graphql`
    query {
        wpPage(slug: {eq: "retail"}) {
            id
            date
            databaseId
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            link
            slug
            template {
              ... on WpTemplate_Sector_Cat {
                templateName
                acfdescription {
                  titleFont2
                  titleFont1
                  fieldGroupName
                  description
                }
              }
            }
            wpChildren {
              nodes {
                slug
                ... on WpPage {
                  id
                  title
                  featuredImage {
                    node {
                      sourceUrl
                    }
                  }
                  template {
                    ... on WpTemplate_Sector {
                      templateName
                      sectors {
                        fieldGroupName
                        tSectorDescription
                        tSectorFullDescription
                        tSectorName
                        tSectorName2
                        tSectorItems {
                          tSectorItemsSubImage
                          tSectorItemsSubLink
                          tSectorItemsSubLogo
                          tSectorItemsSubName
                          tSectorItemsSubValue
                          fieldGroupName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          
    }
`

export default WpPost